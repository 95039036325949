import React, { useState } from 'react'
import { Icons } from '../../../icons'

const MonthCashflow = () => {
        // Separate state for each dropdown
        const [cashFlowOpen, setCashFlowOpen] = useState(false);
    
        // Toggle functions for each dropdown
        const toggleCashFlow = () => setCashFlowOpen(!cashFlowOpen);
    
  return (
    <div>      <h1 className="list-heading">22A Monthly paid amount is added and shown in cash flow</h1>
    <br />
    <table className="">
        <thead>
            <tr className="bg-[#78222E] text-white">
                {/* Cash Flow Dropdown */}
                <th className="list-th-common relative">
                    <span
                        className="dropdown-table-btn flex justify-center items-center"
                        onClick={toggleCashFlow}
                    >
                        Cash Flow{" "}
                        {cashFlowOpen ? (
                            <Icons.arrowclose size={24} />
                        ) : (
                            <Icons.arroopen size={24} />
                        )}
                    </span>
                    {cashFlowOpen && (
                        <ul className="dropdown-table-menu-head">
                            <li className="dropdown-table-menu-text">Month</li>
                            <li className="dropdown-table-menu-text">Year</li>
                        </ul>
                    )}
                </th>
            </tr>
        </thead>
        <tbody>
            {[...Array(4)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {[...Array(0)].map((_, colIndex) => (
                        <td key={colIndex} className="td-cash"></td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table></div>
  )
}

export default MonthCashflow