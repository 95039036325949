import React from "react";
import Safety from "./Safety";

const WorkMethodlogy = () => {
  return (
    <div className="">
      <div className="">
        <Safety />
        <div className="over-flow">
        <table className="table-in-cashflow ">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th className="list-th-common ">Attach File</th>
              <th className="list-th-common ">Checked By</th>
              <th className="list-th-common ">Approved By</th>
              <th className="list-th-common ">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(4)].map((_, colIndex) => (
                  <td key={colIndex} className="td-cash "></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
};

export default WorkMethodlogy;
