import React from 'react'

const DPR = () => {
  return (
    <div className=" ">
      <h1 className="list-heading mb-6">DPR Report</h1>
    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common">Date</th>
                    <th className="list-th-common">File</th>
                       </tr>
            </thead>
            <tbody>
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(2)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            >
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
  )
}

export default DPR