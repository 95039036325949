import React, { useState } from 'react'
import ProcurementPlanHead from '../procurement/ProcurementPlanHead'
import ProcurementPlanList from '../procurement/ProcurementPlanList'
import ProcurementTablesecond from './ProcurementTablesecond'
import ProcurementSummery from './ProcurementSummery'

const ProcurementPlan = () => {
    const [activeTab, setActiveTab] = useState('Procurement-Plan')

    const handleTabs = (tabs) => {
      setActiveTab(tabs)
  }  

  return (
    <div>
      <ProcurementPlanHead activeTab={activeTab} onTabChange={handleTabs} />
      {/* <ProcurementPlanList />
       <ProcurementTablesecond /> */}
       {activeTab === 'Procurement-Plan' && <div><ProcurementPlanList /> <ProcurementTablesecond /></div>}
       {activeTab === 'Procurement-Summary' && <ProcurementSummery /> }

    </div>
  )
}

export default ProcurementPlan