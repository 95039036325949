import React, { useState } from 'react'
import { Icons } from '../../../../icons'

const ProcurementPlanList = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className=" ">
            <div className="over-flow">
                <table className="table-in-cashflow ">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common ">S. No.</th>
                        <th className="list-th-common ">Package Name</th>
                            <th className="list-th-common ">BOQ item Name</th>
                           
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn" onClick={toggleDropdown}>
                                    Type{" "}
                                    {isOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Material</li>
                                        <li className="dropdown-table-menu-text">Labour</li>
                                        <li className="dropdown-table-menu-text">Equipment</li>
                                        <li className="dropdown-table-menu-text">Contract</li>
                                        <li className="dropdown-table-menu-text">Consumables</li>
                                        <li className="dropdown-table-menu-text">Rental</li>
                                    </ul>
                                )}
                            </th>

                            <th className="list-th-common ">Grade/Specification</th>
                            <th className="list-th-common ">Total Quantity</th>
                            <th className="list-th-common">
                                Unit
                            </th>
                            <th className="list-th-common">Lead Time</th>
                            <th className="list-th-common">Quotation 1 exl GST</th><th className="list-th-common">Quotation 1 exl GST</th><th className="list-th-common">Quotation 1 exl GST</th><th className="list-th-common">Total Amount</th><th className="list-th-common">Attach Files</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(13)].map((_, colIndex) => (
                                    <td key={colIndex} className="td-cash">
                                        {/* Check if it's the last column */}
                                        {colIndex === 12 && (
                                            <Icons.attechfile />
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                </table>
                </div>
                <div className=' flex flex-wrap gap-2 justify-between pt-4'>
                    <button className="save-btn">Add</button>
                    <div className=' gap-4 flex flex-wrap'>
                        <button className="ammount-btn">Ammount</button>
                        <button className="ammount-btn">Ammount</button>
                        <button className="ammount-btn">Ammount</button>
                    </div>
                </div>
                <div className=' flex justify-end py-4'>
                    <button className="save-btn">Total Project Budget  :   Rs  /-</button>
                </div>
           
        </div>
    )
}

export default ProcurementPlanList