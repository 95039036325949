import React, { useState } from 'react'
import DailyProgressReport from './DailyProgressReport'
import DPR from './DPR'

const DailyProgress = () => {
    const [activeTab, setActiveTab] = useState('daily-progress')
        const [isOpen, setIsOpen] = useState(false);
        const projects = ["Project A", "Project B", "Project C", "Project D"];

  return (<>
    <div className="list-header">
         <div className="list-tab-btn">
                    <span onClick={() => setActiveTab('daily-progress')} className={` ${activeTab === 'daily-progress' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`} >Daily Progress</span>
                    <span onClick={() => setActiveTab('dpr-report')} className={` ${activeTab === 'dpr-report' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`}>DPR Report</span>
         </div>
           <div className=' flex gap-4'>
                             <div className="dropdown-relative">
                                 {/* Button */}
                                 <button
                                     className="dropdown-container-btn"
                                     onClick={() => setIsOpen(!isOpen)}
                                 >
                                     <span>Select Project</span>
                                     <span>
                                         {/* Replace with your Icons.arrowdropdown */}
                                         <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                             fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor"
                                         >
                                             <path
                                                 strokeLinecap="round"
                                                 strokeLinejoin="round"
                                                 strokeWidth={2}
                                                 d="M19 9l-7 7-7-7"
                                             />
                                         </svg>
                                     </span>
                                 </button>
         
                                 {/* Dropdown Menu */}
                                 {isOpen && (
                                     <div className="dropdown-menu-container">
                                         <ul className="">
                                             {projects.map((project, index) => (
                                                 <li
                                                     key={index} onClick={() => {
                                                         setIsOpen(false);
                                                         alert(`Selected: ${project}`);
                                                     }}
                                                 >
                                                     {project}
                                                 </li>
                                             ))}
                                         </ul>
                                     </div>
                                 )}
                             </div>
                         </div>
    </div>
      <div className=''>
      {activeTab === 'daily-progress' && <DailyProgressReport />}
      {activeTab === 'dpr-report' && <DPR />}
      </div>
      </>)
}

export default DailyProgress