import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import AddStock from './AddStock'

const StockList = () => {
  const [StockModal, setStockModal] = useState(false)

  const handleStockPopUp = () => {
    setStockModal(!StockModal)
  }

  const handleStockPopUpClose = () => {
    setStockModal(false)
  }

  return (
    <div>   {/* Header */}
      <div className="list-header">
        <h1 className="list-heading">Stock</h1>
        <div className=' flex gap-4'>
          <button onClick={handleStockPopUp} className="list-add-btn">
            <span>Add Stock</span> <span><Icons.add size={20} /></span>
          </button>
        </div>
      </div>
      <div className="over-flow">
        <table className="table-in-cashflow">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th className="list-th-common ">Sr No.</th>
              <th className="list-th-common ">Date</th>
              <th className="list-th-common ">Item No.</th>
              <th className="list-th-common ">Quantity</th>
              <th className="list-th-common ">Unit</th>
              <th className="list-th-common ">Description</th>

            </tr>
          </thead>

          <tbody>

            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(6)].map((_, colIndex) => (
                  <td
                    key={colIndex}
                    className="td-cash"
                  ></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddStock isOpen={StockModal} onClose={handleStockPopUpClose} />

    </div>
  )
}

export default StockList