import React from 'react'
import Safety from './Safety'

const HSEIntroduction = () => {
  return (
    <div className="">
        <Safety />
    <div className="over-flow">
      
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Sr. No</th>
                    <th className="list-th-common ">Name of Worker</th>
                    <th className="list-th-common ">Designation</th>
                    <th className="list-th-common ">Company Name</th>
                    <th className="list-th-common ">Aadhar No.</th>

                    <th className="list-th-common ">Pan No.</th>

                    <th className="list-th-common">
                        Signature
                    </th>
                    <th className="list-th-common">
                        Inuction No.
                    </th>
                       </tr>
            </thead>
            <tbody>
                {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(8)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div> 
  )
}

export default HSEIntroduction