import React from 'react'

const AccessLog = () => {
  return (
    <div className="">
                <h1 className="list-heading mb-6">Access Log</h1>
                <div className='over-flow'>
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">User Name</th>
                            <th className="list-th-common ">Item</th>
                            <th className="list-th-common ">Old value</th>
                            <th className="list-th-common ">New value</th>
                            
                            <th className="list-th-common ">Date and time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(5)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(5)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    >
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
  )
}

export default AccessLog