import React from 'react'
import Safety from './Safety'

const WorkPermits = () => {
  return (
    <div className="">
    <Safety />
    <div className="over-flow">

        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Attach File</th>
                    <th className="list-th-common ">Activity Name</th>
                    <th className="list-th-common ">Expiry Date</th>
                    <th className="list-th-common ">Submitted Date</th>
                    </tr>
            </thead>
            <tbody>
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(4)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash "
                            >
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <div className=' flex justify-end pt-4'>
      <h1 className='send-btn'>Send as message</h1>
      </div>
</div> 
  )
}

export default WorkPermits