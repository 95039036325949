import React, { useState } from "react";
import { Icons } from "../../../../icons";
import AddComapny from "./AddComapny";
import EditComapny from "./EditCompany";
import Card from "../../../../components/dashboard/card/Card";
import TableLayout from "../../../../components/ui/TableLayout";
import ContainerBox from "../../../../components/ui/ContainerBox";

const CompanyList = () => {
  const companylist = [
    {
      id: 1,
      name: "Green Valley",
      subtitle: " GV001",
      description: "123 Maple Street, Cityville",
      image: "https://via.placeholder.com/50",
      gst: "GST12345",
      pan: "PAN67890",
      actions: [
        {
          label: "Edit",
          onClick: () => console.log("Edit Clicked for Project 1"),
          className: "bg-blue-500 text-white px-2 py-1",
        },
        {
          label: "Read",
          onClick: () => console.log("Read Clicked for Project 1"),
          className: "bg-green-500 text-white px-2 py-1",
        },
      ],
    },
    {
      id: 2,
      name: "Blue Sky Apartments",
      subtitle: "BS002",
      description: " 456 Oak Avenue, Townsville",
      image: "https://via.placeholder.com/50",
      gst: "GST98765",
      pan: "PAN43210",
      actions: [
        {
          label: "Edit",
          onClick: () => console.log("Edit Clicked for Project 2"),
          className: "bg-blue-500 text-white px-2 py-1",
        },
        {
          label: "Read",
          onClick: () => console.log("Read Clicked for Project 2"),
          className: "bg-green-500 text-white px-2 py-1",
        },
      ],
    },
  ];
  const column = [
    {
      Header: "Company Name",
      rowKey: "companyName",
    },
    {
      Header: "Company Abbreviation",
      rowKey: "companyAbbreviation",
    },
    {
      Header: "Address",
      rowKey: "address",
    },
    {
      Header: "GST No",
      rowKey: "gstNo",
    },
    {
      Header: "Pan No",
      rowKey: "panNo",
      render: (row) => row?.image || "--",
    },
    {
      Header: "Logo",
      rowKey: "logo",
    },
    {
      Header: "Action",
      rowKey: "action",
      render: () => (
        <div className="flex justify-center gap-2">
          <Icons.edit onClick={handleEditComapnyModel} className="cursor-pointer" size={24} />
          <Icons.Delete size={24} />
        </div>
      ),
    },
  ];
  const data = [
    {
      companyName: "Tech Solutions",
      companyAbbreviation: "TS",
      address: "123 Tech Street, Silicon Valley",
      gstNo: "GST123456",
      panNo: "PAN123456",
      logo: "https://via.placeholder.com/150",
      action: "Edit",
    },
    {
      companyName: "Innovative Creations",
      companyAbbreviation: "IC",
      address: "456 Innovation Drive, Tech City",
      gstNo: "GST654321",
      panNo: "PAN654321",
      logo: "https://via.placeholder.com/150",
      action: "Edit",
    },
    {
      companyName: "Future Enterprises",
      companyAbbreviation: "FE",
      address: "789 Future Lane, Startup Town",
      gstNo: "GST789123",
      panNo: "PAN789123",
      logo: "https://via.placeholder.com/150",
      action: "Edit",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const projects = ["Project A", "Project B", "Project C", "Project D"];
  const [AddComapnyModel, setAddCompanyModel] = useState(false);
  const [EditComapnyModel, setEditCompanyModel] = useState(false);

  const handleComapnyModel = () => {
    setAddCompanyModel(!AddComapnyModel);
  };

  const handleClose = () => {
    setAddCompanyModel(false);
  };
  const handleEditComapnyModel = () => {
    setEditCompanyModel(!EditComapnyModel);
  };

  const handleEditClose = () => {
    setEditCompanyModel(false);
  };

  const handlRead = (id) => {
    console.log(id);
  };
  return (
    <ContainerBox>
      {/* Header */}
      <div className="list-header">
        <h1 className="list-heading">3D Company List</h1>
        <div className=" flex gap-4">
          <button onClick={handleComapnyModel} className="list-add-btn">
            <span>Add Company</span>{" "}
            <span>
              <Icons.add size={20} />
            </span>
          </button>
        </div>
      </div>

      {/* Table */}
      <TableLayout columns={column} data={data} />
      {/* <div className="list-table-head">
        <table className="list-table">
          <thead className="list-table-container">
            <tr className="list-table-container">
              <th className="list-th">Company Name</th>
              <th className="list-th">Company Abbreviation</th>
              <th className="list-th">Address</th>
              <th className="list-th">GST No</th>
              <th className="list-th">Pan No</th>
              <th className="list-th">Logo</th>
              <th className="list-th">Action</th>
            </tr>
          </thead>
          <tbody>
            {companylist.map((item, id) => (
              <tr className="list-tr ">
                <td className="list-th">{item.name}</td>
                <td className="list-th">{item.subtitle}</td>
                <td className="list-th">{item.description}</td>
                <td className="list-th">{item.gst}</td>
                <td className="list-th">{item.pan}</td>
                <td className="list-th">
                  <img src={item.image} alt="Project" className="list-img" />
                </td>
                <td className=" list-th list-action ">
                  <Icons.edit
                    onClick={handleEditComapnyModel}
                    className=" cursor-pointer"
                    size={24}
                  />{" "}
                  <Icons.Delete size={24} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      {/* <div className="grid gap-6 md:hidden sm:grid-cols-2 grid-cols-1 ">
        {companylist.map((company)=>(
        <Card
        key={company.id}
        title={company.name}
        subtitle={`${company.subtitle} || ${company.gst} || ${company.pan}`}
        description={`Address : ${company.description} `}
        image={company.image}
        actions={[
          {
            label:<Icons.edit size={20} />,
            onClick: onclick={handleComapnyModel},
            className:""
          },
          {
            label:<Icons.read size={20} />,
            onclick:() =>handlRead(company.id),
            className:''
          }
        ]}
        />
      ))}
      </div> */}
      <AddComapny isOpen={AddComapnyModel} isClose={handleClose} />
      <EditComapny isOpen={EditComapnyModel} isClose={handleEditClose} />
    </ContainerBox>
  );
};

export default CompanyList;
