import React, { useState } from 'react';
import { Icons } from '../../../icons';
import AddBills from './AddBills';
import BillTable from './BillTable';
import MonthCashflow from './MonthCashflow';

const Bill = () => {
    // Separate state for each dropdown
    const [addbill,setAddBill] = useState(false)
    const [activeTab,setActiveTab] = useState('bills')

    // Add Bill Modal

    const handleBillModal = () =>{
        setAddBill(!addbill)
    }

    const handleCloseBillModal = () =>{
        setAddBill(false)
    }

    return (
        <div className="">
            <div className="list-header">
                           <div className="list-tab-btn">
                               <span onClick={() => setActiveTab('bills')} className={` ${activeTab === 'bills' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`} >Bills </span>
                               <span onClick={() => setActiveTab('cashflow')} className={` ${activeTab === 'cashflow' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`}>CashFlow</span>
                           </div>
                               <button onClick={handleBillModal} className={` list-add-btn`}>
                                   Add Bills <Icons.add />
                               </button>
                       </div>
          {activeTab==='bills' && <BillTable/>}
          
{activeTab === 'cashflow' && <MonthCashflow />
}            <AddBills isOpen={addbill} onClose={handleCloseBillModal} />
        </div>
    );
};

export default Bill;
