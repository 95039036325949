import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import AddProject from './AddProject';
import { useLocation } from 'react-router-dom';
import propTypes from 'prop-types'
import ProcurementPlanList from './ProcurementPlanList';

const ProcurementPlanHead = ({activeTab,onTabChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const { pathname } = useLocation()
    console.log(pathname)
    const [addProjectModal, setAddProjectModal] = useState(false)

    const handleAddProjectModal = () => {
        setAddProjectModal(!addProjectModal)
    }

    const handleclose = () => {
        setAddProjectModal(false)
    }

    const projects = ["Project A", "Project B", "Project C", "Project D"];
    const accessusers = ["users A", "users B", "users C", "users D"];
    return (
        <div>  
            
              {/* Header */}
            <div className="list-header">
                <div className="list-tab-btn">
                    <span onClick={() => onTabChange('Procurement-Plan')} className={`${activeTab === 'Procurement-Plan' ? ' bg-gray-500':''}  md:px-6 px-3 py-1 rounded-md`} >4 Procurement Plan</span>
                    <span onClick={() => onTabChange('Procurement-Summary')} className={`${activeTab === 'Procurement-Summary' ? 'bg-gray-500' : ''}  md:px-8 py-1 px-3 rounded-md`}>Procurement Summary</span>
                </div>
                <div className= {`flex gap-4 flex-wrap`}>
                    {/* Select Project Dropdwon */}

                        <div className={`dropdown-relative ${activeTab === 'Procurement-Summary' ? 'hidden' :''}`}>
                            {/* Button */}
                            <button
                                className="dropdown-container-btn"
                                onClick={() => setIsOpen1(!isOpen1)}
                            >
                                <span>Access to users</span>
                                <span>
                                    {/* Replace with your Icons.arrowdropdown */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`h-5 w-5 transform ${isOpen1 ? "rotate-180" : ""}`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </span>
                            </button>

                            {/* Dropdown Menu */}
                            {isOpen1 && (
                                <div className="dropdown-menu-container">
                                    <ul className="">
                                        {accessusers.map((project, index) => (
                                            <li
                                                key={index}
                                                className=""
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    alert(`Selected: ${project}`);
                                                }}
                                            >
                                                {project}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}
                                            className=""
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className={`${activeTab === 'Procurement-Summary' ? 'hidden' :''}`}>
                        <button onClick={handleAddProjectModal} className={`list-add-btn `}>
                            <span>Add New Item</span> <span><Icons.add size={20} /></span>
                        </button>
                        </div>
                </div>
            </div>
            <AddProject isOpen={addProjectModal} onClose={handleclose} />
 
        </div>
    )
}


ProcurementPlanHead.propTypes = {
    activeTab: propTypes.string.isRequired,
    onTabChange: propTypes.func.isRequired,
  };

export default ProcurementPlanHead