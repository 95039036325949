import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Icons } from '../../../icons';
import AddSafetyTrainingDetails from './AddSafetyTrainingDetails';
import AddWorkMethodlogy from './AddWorkMethodlogy';
import AddWorkPermits from './AddWorkPermits';


const Safety = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addSafetydetails,setAddSafetyDetails] = useState(false)
  const [addWorkMethodlogydetails,setAddWorkMethodlogyDetails] = useState(false)
  const [addWorkPermits, setAddWorkPermits] = useState(false)

  const location = useLocation()
  const pathname = location.pathname
  const projects = ["Project A", "Project B", "Project C", "Project D"];
// For Safety Training
  const handleSafetyModal = () =>{
    setAddSafetyDetails(!addSafetydetails)
  }

  const handleClose = () =>{
    setAddSafetyDetails(false)
  }
  // For Work Methodlogy
  const handleWorkMethodlogyModal = () =>{
    setAddWorkMethodlogyDetails(!addWorkMethodlogydetails)
  }

  const handleWorkMethodlogyModalClose = () =>{
    setAddWorkMethodlogyDetails(false)
  }
    // For Work Permits
    const handleWorkPermitsModal = () =>{
      setAddWorkPermits(!addWorkPermits)
    }
  
    const handleWorkPermitsModalClose = () =>{
      setAddWorkPermits(false)
    }
  return (
    <div>
      {/* Header */}
      <div className="list-header">
        {console.log(pathname)}

        <div>
          {pathname === "/dashboard/hse/Introduction" && (
            <h1 className="list-heading">14A HSE Induction</h1>
          )}
          {pathname === "/dashboard/HIRA" && (
            <h1 className="list-heading ">14B Hazard Identification and Risk Assessment</h1>
          )}
          {pathname === "/dashboard/safety/training" && (
            <h1 className="list-heading ">14C Safety Training/ Meeting/ Toolbox Record</h1>
          )}
          {pathname === "/dashboard/work/methodlogy" && (
            <h1 className="list-heading ">14D Work Methodology Statement</h1>
          )}
          {pathname === "/dashboard/work/permits" && (
            <h1 className="list-heading ">14E Work Permits</h1>
          )}
          {pathname === "/dashboard/safety/violation" && (
            <h1 className="list-heading ">14F Safety Violation</h1>
          )}
        </div>

        <div className=' flex gap-4'>
          {/* Select Project Dropdwon */}
          <div className="dropdown-relative">
            {/* Button */}
            <button
              className="dropdown-container-btn"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>Select Project</span>
              <span>
                {/* Replace with your Icons.arrowdropdown */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="dropdown-menu-container">
                <ul className="">
                  {projects.map((project, index) => (
                    <li
                      key={index}
                      className=""
                      onClick={() => {
                        setIsOpen(false);
                        alert(`Selected: ${project}`);
                      }}
                    >
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {pathname ==="/dashboard/safety/training" && (
          <button onClick={handleSafetyModal}   className="list-add-btn">
                    Add Details<Icons.add />
                  </button>
           )}
             {pathname ==="/dashboard/work/methodlogy" && (
          <button onClick={handleWorkMethodlogyModal}   className="list-add-btn">
                    Work Methodlogy<Icons.add />
                  </button>
           )}
              {pathname ==="/dashboard/work/permits" && (
          <button onClick={handleWorkPermitsModal}   className="list-add-btn">
                    Work Permits<Icons.add />
                  </button>
           )}
        </div>
        
      </div>
      <AddSafetyTrainingDetails isOpen={addSafetydetails} onClose={handleClose} />
      <AddWorkMethodlogy isOpen={addWorkMethodlogydetails} onClose={handleWorkMethodlogyModalClose} />
      <AddWorkPermits isOpen={addWorkPermits} onClose={handleWorkPermitsModalClose} />

    </div>
  )
}

export default Safety