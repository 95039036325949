import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import TotalManpower from './TotalManpower';
import TotalManpowersecond from './TotalManpowersecond';

const ManPower = () => {
  const [activeTab, setActiveTab] = useState('manpower')
  const [isOpen, setIsOpen] = useState(false);
  const projects = ["Project A", "Project B", "Project C", "Project D"];
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleDropdown = () => {
    setIsOpen1(!isOpen1);
  };

  return (
    <div>
      {/* Header */}
      <div className="list-header">
        <div className="list-tab-btn">
          <span onClick={() => setActiveTab('manpower')} className={` ${activeTab === 'manpower' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`} >Manpower</span>
          <span onClick={() => setActiveTab('total-manpower')} className={` ${activeTab === 'total-manpower' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`}>Total Manpower
          </span>
        </div>
        <div className=' flex gap-4'>
          <div className="dropdown-relative">
            {/* Button */}
            <button
              className="dropdown-container-btn"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>Select Stored By Description Name</span>
              <span>
                {/* Replace with your Icons.arrowdropdown */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="dropdown-menu-container">
                <ul className="">
                  {projects.map((project, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setIsOpen(false);
                        alert(`Selected: ${project}`);
                      }}
                    >
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {activeTab ==='manpower' &&
      <div className=" ">
        <h1 className="list-heading mb-6">20A Manpower</h1>

        <div className="over-flow">
          <table className="table-in-cashflow">
            <thead>
              <tr className="bg-[#78222E] text-white">
                <th className="list-th-common ">Date</th>
                <th className="list-th-common ">Name of person</th>
                <th className="list-th-common ">Aadhar Card</th>
                <th className="list-th-common relative flex justify-center items-center">
                  <span className="dropdown-table-btn" onClick={toggleDropdown}>
                    Company Name{" "}
                    {isOpen ? (
                      <Icons.arrowclose size={24} />
                    ) : (
                      <Icons.arroopen size={24} />
                    )}
                  </span>
                  {isOpen1 && (
                    <ul className="dropdown-table-menu-head top-6">
                      <li className="dropdown-table-menu-text">Take picture</li>

                    </ul>
                  )}
                </th>
                <th className="list-th-common relative">
                  <div className='flex justify-center items-center'>
                    <span className="dropdown-table-btn" onClick={toggleDropdown}>
                      Incident Report{" "}
                      {isOpen ? (
                        <Icons.arrowclose size={24} />
                      ) : (
                        <Icons.arroopen size={24} />
                      )}
                    </span>
                    {isOpen1 && (
                      <ul className="dropdown-table-menu-head top-6">
                        <li className="dropdown-table-menu-text ">Take picture</li>

                      </ul>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(5)].map((_, colIndex) => (
                    <td
                      key={colIndex}
                      className="td-cash"
                    ></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
}

    {activeTab === 'total-manpower' && <div><TotalManpower />
      <TotalManpowersecond /> </div>}
    </div>
  )
}

export default ManPower