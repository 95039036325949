import React, { useState } from "react";

const StockInward = () => {
  const [isOpen, setIsOpen] = useState(false);
  const projects = ["Project A", "Project B", "Project C", "Project D"];
  return (
    <div className="">
      <div className="list-header">
        <h1 className="list-heading">Stock Inward Register</h1>
        <div className=" flex gap-4">
          {/* Select Project Dropdwon */}
          <div className="dropdown-relative">
            {/* Button */}
            <button
              className="dropdown-container-btn"
              onClick={() => setIsOpen(!isOpen)}>
              <span>Select Project</span>
              <span>
                {/* Replace with your Icons.arrowdropdown */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="dropdown-menu-container">
                <ul className="">
                  {projects.map((project, index) => (
                    <li
                      key={index}
                      className=""
                      onClick={() => {
                        setIsOpen(false);
                        alert(`Selected: ${project}`);
                      }}>
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="over-flow">
        <table className="table-in-cashflow">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th colSpan="4" className="list-th-common ">
                Inward
              </th>

              <th colSpan="5" className="list-th-common ">
                Outward
              </th>
            </tr>
          </thead>
          <tr className="bg-gray-100">
            <th className="list-th-common ">Item No</th>
            <th className="list-th-common ">Description</th>
            <th className="list-th-common ">Quantity</th>
            <th className="list-th-common ">Unit</th>
            <th className="list-th-common ">Issue to Date</th>

            <th className="list-th-common">User Name</th>
            <th className="list-th-common">Company Name</th>
            <th className="list-th-common">Quantity</th>
            <th className="list-th-common">Balance Quantity</th>
          </tr>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(9)].map((_, colIndex) => (
                  <td key={colIndex} className="td-cash"></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StockInward;
