import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import Select from "react-select"; // Correct import for react-select

const AddMaterial = ({ isOpen, onClose }) => {
  const options = [
    { value: "Abc", label: "ab" },
    { value: "Xyz", label: "xy" },
    { value: "mn", label: "mn" },
    { value: "svelte", label: "Svelte" },
    { value: "svelte", label: "Svelte" },


    { value: "svelte", label: "Svelte" },

  ];

  // State to handle selected option
  const [selectedOption, setSelectedOption] = useState(null);

  // Function to handle change
  const handleChange = (option) => {
    setSelectedOption(option);
    console.log("Selected:", option);
  };


  // Custom styles for the Select component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #78222E17" : "1px solid #ccc", // Change border color on focus
      outline: state.isFocused ? "1px solid #78222E17" : "none", // Change outline color on focus
      boxShadow: state.isFocused ? "#78222E17" : "none", // Box-shadow on focus
      transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for border and shadow
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#78222E17" : "#fff", // Background color for focused option
      color: state.isFocused ? "#78222E" : "#000", // Color for focused option
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set max height for the dropdown
      overflowY: "auto", // Enable vertical scrolling
      borderRadius: "0px", // Optional: remove border radius if needed
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0", // Remove any default padding
    }),
  };

  if (!isOpen) {
    return null
  }
  return (
    <div className="add-main">
      <div className="add-popup">
        {/* Popup Header */}
        <div className="add-pop-header">
          <h2 className="add-heading">Add Material Requisition</h2>
          <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
        </div>
        <hr className="mt-5 mx-5" />
        {/* Form */}
        <form className="p-6">
          {/* Row 1 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Requisition No
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
            <div>
              <label className="add-lable">
                Requisition By
              </label>
              <input
                type="text"
                className="add-input"
                placeholder="Enter Requisition"
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Date
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
            <div>
              <label className="add-lable">
                Date required
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
          </div>

          {/* Row 3 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Quantity
              </label>
              <input
                type="number"
                className="add-input"
                placeholder='quantity'
              />
            </div>
            {/* <div>
              <label className="add-lable">
                Unit
              </label>
              <input
                type="text"
                className="add-input"
                placeholder="Enter unit"
              />
            </div> */}
            <div>
              <label className="add-lable">
                Description
              </label>
              <Select
                options={options} // Options for the dropdown
                value={selectedOption} // Controlled selected value
                onChange={handleChange} // Callback on selection change
                isClearable // Add a clear button
                styles={customStyles} // Apply custom styles here
                placeholder="Select Description..." // Placeholder text
              />

            </div>
          </div>



          <button
            className="in-add-confirm-btn"
          >
            Add new Item
          </button>

          {/* Buttons */}
          <div className="add-btn-container">
            <button
              type="button"
              onClick={onClose}
              className="in-add-cancel-btn"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="in-add-confirm-btn"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddMaterial