import React, { useState } from 'react'
import { Icons } from '../../../../icons';

const ManpowerRequirement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };


    return (
        <div className=" shadow-md">
            <div className="over-flow">
                <table className=" w-full ">
                   
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common relative">
                                <span className="dropdown-table-btn" onClick={toggleDropdown}>
                                    Activity{" "}
                                    {isOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Activity 01</li>
                                        <li className="dropdown-table-menu-text">Activity 02</li>
                                        <li className="dropdown-table-menu-text">Activity 03</li>
                                        <li className="dropdown-table-menu-text">Activity 04</li>
                                        
                                    </ul>
                                )}
                            </th>
                            <th className="list-th-common ">Start Date</th>
                            <th className="list-th-common ">Milestone Date</th>
                            <th className="list-th-common ">Total Quantity</th>
                            <th className="list-th-common ">Average Quantity Required per day</th>
                            <th className="list-th-common">
                                Productivity Rate per team
                            </th>
                            <th className="list-th-common">Total Manpower Required</th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn" onClick={toggleDropdown1}>
                                    Manpower Type{" "}
                                    {isOpen1 ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen1 && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Mason</li>
                                        <li className="dropdown-table-menu-text">Carpenter</li>
                                        <li className="dropdown-table-menu-text">Fitter</li>
                                        <li className="dropdown-table-menu-text">Plumber</li>
                                        <li className="dropdown-table-menu-text">Electrician</li>
                                    </ul>
                                )}
                            </th>                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(5)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(8)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ManpowerRequirement