import React from 'react'

const Visitor = () => {
  return (<>
    <div className="list-header">
        <h1 className="list-heading">Visitor</h1>

      </div>  
    <div className="">
    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Date</th>
                    <th className="list-th-common ">Person Name</th>
                    <th className="list-th-common ">Company Name</th>
                    <th className="list-th-common ">Meeting </th>
                    <th className="list-th-common ">In Time</th>
                    <th className="list-th-common">
                        Out Time
                    </th>
                   

                </tr>
            </thead>
            <tbody>
                {[...Array(10)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(6)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
</> )
}

export default Visitor