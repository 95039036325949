import React from "react";
import ContainerBox from "../../../../components/ui/ContainerBox";
import TableLayout from "../../../../components/ui/TableLayout";

const PlanningTab = () => {
  const column = [
    {
      Header: "S. No.",
      rowKey: "serialNumber",
    },
    {
      Header: "Activity Name",
      rowKey: "activityName",
    },
    {
      Header: "Total Quantity",
      columns: [{ Header: "(Qty/Day)", rowKey: "totalQuantity" }],
    },
    {
      Header: "Productivity Rate per Day ",
      columns: [
        { Header: "(Quantity/Productivity) C/D", rowKey: "productivityRate" },
      ],
    },
    {
      Header: "Duration",
      rowKey: "duration",
    },
    {
      Header: "Preceding Activity",
      rowKey: "precedingActivity",
    },
    {
      Header: "Start Date",
      columns: [{ Header: "(E+F)", rowKey: "startDate" }],
    },
    {
      Header: "Milestone Date ",
      columns: [{ Header: "(Freeze Milestone Date)", rowKey: "milestoneDate" }],
    },
    {
      Header: "Freeze",
      rowKey: "freeze",
    },
  ];

  const data = [
    {
      serialNumber: 1,
      activityName: "Excavation",
      totalQuantity: "1000 m³",
      productivityRate: "50 m³/day",
      duration: "20 days",
      precedingActivity: "Site Clearance",
      startDate: "2024-01-01",
      milestoneDate: "2024-01-21",
      freeze: "Yes",
    },
    {
      serialNumber: 2,
      activityName: "Foundation",
      totalQuantity: "500 m³",
      productivityRate: "25 m³/day",
      duration: "20 days",
      precedingActivity: "Excavation",
      startDate: "2024-01-22",
      milestoneDate: "2024-02-11",
      freeze: "Yes",
    },
    {
      serialNumber: 3,
      activityName: "Framing",
      totalQuantity: "2000 m²",
      productivityRate: "100 m²/day",
      duration: "20 days",
      precedingActivity: "Foundation",
      startDate: "2024-02-12",
      milestoneDate: "2024-03-03",
      freeze: "Yes",
    },
  ];

  return (
    <ContainerBox>
      <TableLayout columns={column} data={data} />
    </ContainerBox>
    // <div className=" shadow-md">
    //   <div className="overflow-x-auto">
    //     <table className="table-in-cashflow">
    //       <thead>
    //         <tr className="bg-[#78222E] text-white">
    //           <th className="list-th-common ">S. No.</th>

    //           <th className="list-th-common ">Activity Name</th>
    //           <th className="list-th-common ">Total quantity</th>
    //           <th className="list-th-common ">Productivity rate per day</th>
    //           <th className="list-th-common ">Duration</th>
    //           {/* preceding activity */}
    //           <th className="list-th-common ">Preceding activity</th>

    //           <th className="list-th-common ">Start Date</th>
    //           <th className="list-th-common">Milestone Date</th>
    //           <th className="list-th-common">Freeze</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr className="bg-gray-100">
    //           <td className="tbody-td-cash"></td>
    //           <td className="tbody-td-cash"></td>
    //           <td className="tbody-td-cash text-center">(Qty/Day)</td>
    //           <td className="tbody-td-cash text-center">
    //             (Quantity/ Productivity) C/D
    //           </td>
    //           <td className="tbody-td-cash"></td>
    //           <td className="tbody-td-cash"></td>

    //           <td className="tbody-td-cash text-center">E+F</td>
    //           <td className="tbody-td-cash text-center">
    //             Freeze Milestone Date
    //           </td>
    //           <td className="tbody-td-cash"></td>
    //         </tr>
    //         {[...Array(10)].map((_, rowIndex) => (
    //           <tr key={rowIndex}>
    //             {[...Array(9)].map((_, colIndex) => (
    //               <td key={colIndex} className="td-cash"></td>
    //             ))}
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

export default PlanningTab;
