import React, { useState } from 'react'
import StoreHead from './StoreHead'
import StoreApproved from './StoreApproved'
import StockInward from './StockInward'
import StockList from '../store/StockList'
import MaterialRequisition from './MaterialRequisition'
import { tab } from '@testing-library/user-event/dist/tab'
import StockRegister from './StockRegister'

const Store = () => {
  const [activeTab, setActiveTab] = useState('store')

  const handleTab = (tabs) =>{
    console.log(tabs)
    setActiveTab(tabs)
  } 

 
  const renderTabContent = () =>{
    switch (activeTab) {
      case 'store':
        return <MaterialRequisition />
        case 'stock':
          return <StockList />
          case 'stock-inward':
            return <StockInward />
            case 'stock-register':
              return <StockRegister />
              case 'approved-purchase':
                return   <StoreApproved />
       default :
       return <div>Select a tab to view content</div>;
      }
  }


  return (
    <div>
      <StoreHead activeTab={activeTab} onTabChange={handleTab}/>
      {/* <MaterialRequisition />
      <StoreApproved /> */}

      <div>
     {renderTabContent()}
      </div>
     
    </div>
  )
}

export default Store