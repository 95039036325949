import React from 'react'
import DPR from '../DailyProgress/DPR'
import ManpowerHead from '../Manpower&Requirement/ManpowerHead'

const DailyProgressReport = () => {
  return (
    <>
    <div className="">
    <h1 className="list-heading  mb-6">18 Daily Progress Report</h1>

    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Date</th>
                    <th className="list-th-common ">Activity Name</th>
                    <th className="list-th-common ">Total Quantty</th> 
                    <th className="list-th-common">
                        Executed Quantiy
                    </th>
                    <th className="list-th-common">
                        Balance Quantiy
                    </th>
                    <th colSpan="2" className="list-th-common">
                        Manpower
                    </th>
                    <th className="list-th-common">
                        Milestone Date
                    </th>
                    <th className="list-th-common">
                        Forcast Date
                    </th>
                  
                       </tr>
            </thead>
            <tr className="bg-gray-100">
                            
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>

                            <td className="tbody-td-cash text-center"> Skills </td>
                            <td className="tbody-td-cash text-center">Unskilled </td>

                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>                            
                            </tr>
            <tbody>
                {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(9)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
</>
  )
}

export default DailyProgressReport