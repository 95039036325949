import React, { useState } from 'react'
import { Icons } from '../../../icons'

const BillTable = () => {
    const [quotationOpen, setQuotationOpen] = useState(false);
    const [challanOpen, setChallanOpen] = useState(false);
    const [reconciliationOpen, setReconciliationOpen] = useState(false);
    const [dueDateOpen, setDueDateOpen] = useState(false);
    const [statusOpen, setStatusOpen] = useState(false);
    const toggleQuotation = () => setQuotationOpen(!quotationOpen);
    const toggleChallan = () => setChallanOpen(!challanOpen);
    const toggleReconciliation = () => setReconciliationOpen(!reconciliationOpen);
    const toggleDueDate = () => setDueDateOpen(!dueDateOpen);
    const toggleStatus = () => setStatusOpen(!statusOpen);

    return (<>
    
    <h1 className="list-heading">22 Bills</h1>
    <br />
        <div className="over-flow">
            <table className="table-in-cashflow ">
                <thead>
                    <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common ">Company Name</th>
                        <th className="list-th-common ">Type</th>

                        {/* Quotation Dropdown */}
                        <th className="list-th-common relative">
                            <span
                                className="dropdown-table-btn flex justify-center items-center"
                                onClick={toggleQuotation}
                            >
                                Quotation{" "}
                                {quotationOpen ? (
                                    <Icons.arrowclose size={24} />
                                ) : (
                                    <Icons.arroopen size={24} />
                                )}
                            </span>
                            {quotationOpen && (
                                <ul className="dropdown-table-menu-head">
                                    <li className="dropdown-table-menu-text">Attach File</li>
                                </ul>
                            )}
                        </th>

                        {/* Challan Dropdown */}
                        <th className="list-th-common relative">
                            <span
                                className="dropdown-table-btn flex justify-center items-center"
                                onClick={toggleChallan}
                            >
                                Challan{" "}
                                {challanOpen ? (
                                    <Icons.arrowclose size={24} />
                                ) : (
                                    <Icons.arroopen size={24} />
                                )}
                            </span>
                            {challanOpen && (
                                <ul className="dropdown-table-menu-head">
                                    <li className="dropdown-table-menu-text">Challan</li>
                                    <li className="dropdown-table-menu-text">Measurement Sheet</li>
                                    <li className="dropdown-table-menu-text">Attach File</li>
                                </ul>
                            )}
                        </th>

                        {/* Reconciliation Dropdown */}
                        <th className="list-th-common relative">
                            <span
                                className="dropdown-table-btn flex justify-center items-center"
                                onClick={toggleReconciliation}
                            >
                                Reconciliation{" "}
                                {reconciliationOpen ? (
                                    <Icons.arrowclose size={24} />
                                ) : (
                                    <Icons.arroopen size={24} />
                                )}
                            </span>
                            {reconciliationOpen && (
                                <ul className="dropdown-table-menu-head">
                                    <li className="dropdown-table-menu-text">Attach File</li>
                                </ul>
                            )}
                        </th>

                        {/* Due Date Dropdown */}
                        <th className="list-th-common relative">
                            <span
                                className="dropdown-table-btn flex justify-center items-center"
                                onClick={toggleDueDate}
                            >
                                Due Date{" "}
                                {dueDateOpen ? (
                                    <Icons.arrowclose size={24} />
                                ) : (
                                    <Icons.arroopen size={24} />
                                )}
                            </span>
                            {dueDateOpen && (
                                <ul className="dropdown-table-menu-head">
                                    <li className="dropdown-table-menu-text">Attach File</li>
                                </ul>
                            )}
                        </th>
                        <th className="list-th-common ">Certified By</th>
                        <th className="list-th-common ">Certified Amount </th>

                        {/* Status Dropdown */}
                        <th className="list-th-common relative">
                            <span
                                className="dropdown-table-btn flex justify-center items-center"
                                onClick={toggleStatus}
                            >
                                Status{" "}
                                {statusOpen ? (
                                    <Icons.arrowclose size={24} />
                                ) : (
                                    <Icons.arroopen size={24} />
                                )}
                            </span>
                            {statusOpen && (
                                <ul className="dropdown-table-menu-head">
                                    <li className="dropdown-table-menu-text">Paid</li>
                                    <li className="dropdown-table-menu-text">Unpaid</li>
                                </ul>
                            )}
                        </th>

                        <th className="list-th-common ">Amount Paid</th>
                        <th className="list-th-common ">Balance</th>
                        <th className="list-th-common ">Balance Due</th>
                        <th className="list-th-common ">Attach File</th>
                    </tr>
                </thead>
                <tbody>
                    {[...Array(5)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {[...Array(13)].map((_, colIndex) => (
                                <td key={colIndex} className="td-cash">
                                    {colIndex === 13 ? <Icons.attechfile /> : ""}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </> )
}

export default BillTable