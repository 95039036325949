import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css'; // React Quill styles
import ReactQuill from 'react-quill';

const BiographyComponent = () => {
  const [bio, setBio] = useState('');
  const [status, setStatus] = useState({
    active: false,
    emailVerified: false,
    phoneVerified: false,
    inHomePage: false,
    featured: false,
    proStatus: false,
    premiumStatus: false,
    approvalStatus: 'Approved', // Example status
  });

  const handleCheckboxChange = (field) => {
    setStatus((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <div className="p-4 rounded-md bg-white xl:w-[978px] sm:w-full ">
      {/* Title */}

      {/* Text Editor */}
      <ReactQuill
        value={bio}
        onChange={setBio}
        className=" "
        placeholder="Enter Text"
      />

      {/* Status Section */}
      <div className="flex flex-wrap gap-4">
        {/* {[
          { label: 'Active?', field: 'active' },
          { label: 'Email Verified?', field: 'emailVerified' },
          { label: 'Phone Verified?', field: 'phoneVerified' },
          { label: 'In Home Page?', field: 'inHomePage' },
          { label: 'Featured?', field: 'featured' },
          { label: 'PRO Status', field: 'proStatus' },
          { label: 'Premium Status', field: 'premiumStatus' },
        ].map(({ label, field }) => (
          <label key={field} className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={status[field]}
              onChange={() => handleCheckboxChange(field)}
            />
            {label}
          </label>
        ))} */}

        {/* Status Badge */}
        {/* <div className="col-span-4">
          <span className="inline-block px-3 py-1 bg-green-200 text-green-800 text-sm rounded-full">
            Status: {status.approvalStatus}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default BiographyComponent;
