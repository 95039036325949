import React from 'react'
import Safety from './Safety'

const SafetyViolation = () => {
  return (
    <div className="">
    <Safety />
    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Sr.No</th>
                    <th className="list-th-common ">Date</th>
                    <th className="list-th-common ">Location</th>
                    <th className="list-th-common ">Responsible
                    Company</th>
                    <th className="list-th-common ">Name of Person</th>
                    <th className="list-th-common ">Attach Pic</th>
                    </tr>
            </thead>
            <tbody>
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(6)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash "
                            >
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <div className=' flex justify-end p-4'>
      <h1 className='send-btn'>Send as message</h1>
      </div>
</div> 
  )
}

export default SafetyViolation