import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import AddMaterial from './AddMaterial'

const MaterialRequisition = () => {
      const [MaterialModal, setMaterialModal] = useState(false)
    
    const handleMaterialPopUp = () => {
        setMaterialModal(!MaterialModal)
      }
    
      const handleMaterialPopUpClose = () => {
        setMaterialModal(false)
      }
    
  return (
    <div className="">
          {/* Header */}
                    <div className="list-header">
                        <h1 className="list-heading" >Material Requisition</h1>
                        <button onClick={handleMaterialPopUp} className="list-add-btn">
                            <span>Material Requisition</span> <span><Icons.add size={20} /></span>
                        </button>
                    </div>
    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Sr No.</th>
                    <th className="list-th-common ">Date</th>
                    <th className="list-th-common ">Description</th>
                    <th className="list-th-common ">Quantity</th>
                    <th className="list-th-common ">Required By</th>
                    <th className="list-th-common">
                        Date Required
                    </th>
                </tr>
            </thead>
            <tr className="bg-gray-100">
                            
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td colSpan="2" className="tbody-td-cash text-center">Name of person</td>
                            </tr>
            <tbody>
              
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(6)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <AddMaterial isOpen={MaterialModal} onClose={handleMaterialPopUpClose} />

</div>
  )
}

export default MaterialRequisition