import React from 'react'

const Labour = () => {
  return (
    <div className="mt-8">
    <h1 className="list-heading rounded-t-xl bg-gray-400 text-center py-1 text-white">Labour</h1>
 <div className="overflow-x-auto rounded-b-xl border border-gray-300">

     <table className="table-in-cashflow">
         <thead>
             <tr className="bg-[#78222E] text-white">
                 <th className="list-th-common ">Date</th>
                 <th className="list-th-common ">Type</th>
                 <th className="list-th-common ">Nos.</th>
                   </tr>
         </thead>
         <tbody>
             {[...Array(5)].map((_, rowIndex) => (
                 <tr key={rowIndex}>
                     {[...Array(3)].map((_, colIndex) => (
                         <td
                             key={colIndex}
                             className="td-cash"
                         ></td>
                     ))}
                 </tr>
             ))}
         </tbody>
     </table>
 </div>
</div>
  )
}

export default Labour