import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import ListSecurity from './ListSecurity';
import Visitor from '../security/Visitor'
import Labour from '../security/Labour'
import GetPass from '../security/GetPass'
import MaterialOutWardRegister from './MaterialOutWardRegister';

const Security = () => {
  const [activeTab , setActiveTab] = useState('Security')

  const tabs = [
    {id:'Security', lable:'Security'},
    {id:'Visitor', lable:'Visitor'},
    {id:'Labour', lable:'Labour'},
    {id:'Getpass', lable:'Gatepass'},
  ]
   
  const handleTabs = (tabs) =>{
    setActiveTab(tabs)
  }

  const renderTabs = () =>{
    switch(activeTab) {
      case 'Security' :
        return <ListSecurity />
        case 'Visitor' :
          return <Visitor />
          case 'Labour' :
            return <Labour />
            case 'Getpass' :
              return <GetPass />
              default :
              return <div>No Data</div>
    }
  }

  return (
    <div>
      {/* Header */}
      <div className="center-div">
        <div className="list-tab-btn-all">
          {tabs.map((tab)=>(
                <span onClick={() => handleTabs(tab.id)} key={tab.id} className={`${activeTab === tab.id ? 'bg-gray-500':''}  md:px-6 px-3 py-1 rounded-md`} >{tab.lable}</span>
              ))} 
            </div>
      </div>
      {/* List */}
      {renderTabs()}
    </div>
  )
}

export default Security