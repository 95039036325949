import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../../icons";
import BiographyComponent from "./BiographyComponent";

const AddContract = () => {
  const navigate = useNavigate()
  return (
    <div className="contract-main">
      <div className="relative flex items-center">
        <Icons.arrowleft
          size={20}
          onClick={() => navigate('/dashboard/userlist')}
          className="absolute left-8 text-white top-3 cursor-pointer"
        />
        <h2 className="w-full text-center font-medium">Contract</h2>
      </div>
      {/* Contract Details */}
      <hr className="mt-5 mx-5" />
      <form>
        <div className="contract-grid-container">
          <div>
            <label className="add-lable">Contract</label>
            <input
              type="text"
              placeholder="Contract name"
              className="add-input"
            />
          </div>
          <div>
            <label className="add-lable">Contract Number</label>
            <input
              type="text"
              value="123456"
              readOnly
              className="add-input bg-gray-200"
            />
          </div>
          <div>
            <label className="add-lable">Company Name</label>
            <select className="add-input">
              <option>Select</option>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
          </div>
          <div>
            <label className="add-lable">Package Name</label>
            <input
              type="text"
              placeholder="Package name"
              className="add-input"
            />
          </div>
          <div>
            <label className="add-lable">Project Number</label>
            <input
              type="text"
              placeholder="Move with project plan"
              className="add-input"
            />
          </div>
        </div>

        {/* Add BOQ Section */}
        <hr className="my-2 mx-5" />
        <div className="contract-boq">
          <table className="contract-table-container">
            <thead>
              <tr className="contract-tr">
                <th className="contract-th">Item Code</th>
                <th className="contract-th">Item Description</th>
                <th className="contract-th">Quantity</th>
                <th className="contract-th">Unit</th>
                <th className="contract-th">Rate</th>
                <th className="contract-th">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="contract-th">1.2.1</td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
              </tr>
              <tr>
                <td className="contract-th">1.2.2</td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
                <td className="contract-th"> </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Additional Sections */}
        <hr className="my-2 mx-5" />
        <div className="contract-grid-container ">
          <div>
            <label className="add-lable">Project Plan</label>
            <input
              type="text"
              placeholder="Write here"
              className="add-input"
            />
          </div>
          <div>
            <label className="add-lable">Project Plan Attach File</label>
            <input type="file" className="add-input" />
          </div>
          <div>
            <label className="add-lable">Machinery</label>
            <input
              type="text"
              placeholder="Write here"
              className="add-input"
            />
          </div>
          <div>
            <label className="add-lable">Staff Deployment</label>
            <select className="add-input">
              <option>Write here</option>
            </select>
          </div>
        </div>

        {/* Other Inputs */}
        <hr className="my-2 mx-5" />
        <div className=" flex flex-wrap justify-center gap-4 items-center">
      <BiographyComponent />
      <BiographyComponent />
      <BiographyComponent />
      <BiographyComponent />
      <BiographyComponent />
      <BiographyComponent />
      </div>
        {/* <div className="p-6">
          {["Add Teams and Conditions", "Add Retainership", "Add Updates Damages", "Add Payment Terms", "Add Penalties", "Add Dispute Resolution"].map(
            (label) => (
              <div key={label} className="">
                <label className="add-lable">{label}</label>
                <textarea
                  placeholder="Write with numbering"
                  rows="2"
                  className="add-input"
                ></textarea>
              </div>
            )
          )}
        </div> */}
        <hr className="my-2 mx-5" />
        <div className="contract-grid-container">
          <div>
            <label className="add-lable">Attach File</label>
            <input type="file" className="add-input" />
          </div>
          <div>
            <label className="add-lable">Complete Work Order</label>
            <textarea
              placeholder="Write here"
              rows="2"
              className="add-input"
            ></textarea>
          </div>
        </div>

        {/* Buttons */}
        <div className="contract-btn-container" >
          <button className="in-add-cancel-btn">Cancel</button>
          <button className="in-add-confirm-btn">Confirm</button>
        </div>
      </form>
    </div>
  );
};

export default AddContract;
