import React, { useState } from 'react'
import { Icons } from '../../../../icons';

const TotalManpower = () => {
    const [isOpen1, setIsOpen1] = useState(false);

    const toggleDropdown = () => {
      setIsOpen1(!isOpen1);
    };
  return (
    <div className=" mb-6">
        <h1 className='font-semibold text-xl mb-6'>Total Manpower</h1>
    <div className="over-flow">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Company Name</th>
                    <th className="list-th-common ">Total</th>
                    <th className="list-th-common relative flex justify-center items-center">
                                <span className="dropdown-table-btn" onClick={toggleDropdown}>
                                Gatepass{" "}
                                    {isOpen1 ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen1 && (
                                    <ul className="dropdown-table-menu-head top-6">
                                        <li className="dropdown-table-menu-text">Number of Workers</li>
                                        
                                    </ul>
                                )}
                            </th>
                    <th className="list-th-common">
                        Approved by
                    </th>
                    <th className="list-th-common">
                        Date
                    </th>
                    <th className="list-th-common">
                        Net Total
                    </th>
                       </tr>
            </thead>
            <tbody>
                {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(6)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
  )
}

export default TotalManpower