import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import AddMaterial from './AddMaterial'
import AddStock from './AddStock'
import { tab } from '@testing-library/user-event/dist/tab'

const StoreHead = ({activeTab,onTabChange}) => {
  const [StockModal, setStockModal] = useState(false)

  const tabs = [
    {id:'store', lable:'9 Store'},
    {id:'stock', lable:'Stock'},
    {id:'stock-inward', lable:'Stock Inward'},
    {id:'stock-register', lable:'Stock Register'},
    {id:'approved-purchase', lable:'Approved Purchase'}
  ]
  

  const handleStockPopUp = () => {
    setStockModal(!StockModal)
  }

  const handleStockPopUpClose = () => {
    setStockModal(false)
  }

  return (
    <div>
      {/* Header */}
      <div className="center-div">
        <div className="list-tab-btn-all">
          {tabs.map((tab)=>(
                <span onClick={() => onTabChange(tab.id)} key={tab.id} className={`${activeTab === tab.id ? 'bg-gray-500':''}  md:px-6 px-3 py-1 rounded-md`} >{tab.lable}</span>
              ))} 
            </div>
      </div>
      <AddStock isOpen={StockModal} onClose={handleStockPopUpClose} />
    </div>
  )
}

export default StoreHead