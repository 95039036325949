import React, { useState } from 'react';
import { Icons } from '../../../../icons';

const AccessLog = () => {


  return (
    <div className="">
      {/* Table */}
      <div className="list-table-head over-flow">
        <table className="list-table w-full">
          <thead className=''>
            <tr className="list-table-container">
              <th className="list-th">Drawing Name</th>
              <th className="list-th">Revision</th>
              <th className="list-th">Status</th>
              <th className="list-th">Download By</th>
            </tr>
          </thead>
          <tbody>
            {/* Example Row */}
            <tr className="list-tr">
              <td className="list-th">lorem ipsum</td>
              <td className="list-th">123456</td>
              <td className="list-th"><span className=' text-white bg-green-500 px-6 py-2 rounded-lg'>Approval</span></td>
              <td className="list-th">Karan Thakur</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccessLog;
